import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import './AccessibilityForm.scss';
import AccessibilityImage from '../images/accessibility.png';
import DefaultFontSizeImage from '../images/default_font_size.jpg';
import MediumFontSizeImage from '../images/medium_font_size.jpg';
import LargeFontSizeImage from '../images/large_font_size.jpg';
import DefaultTextSpacingImage from '../images/default_text_spacing.jpg';
import MediumTextSpacingImage from '../images/medium_text_spacing.jpg';
import LargeTextSpacingImage from '../images/large_text_spacing.jpg';
import DefatultContrastImage from '../images/default_contrast.jpg';
import BlackAndYellowContrastImage from '../images/black_and_yellow_contrast.jpg';
import WhiteAndBlackContrastImage from '../images/white_and_black_contrast.jpg';

class AccessibilityForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
        this.isAccessible = document.getElementById('root').getAttribute('class') === 'global-accessible';
        this.siteLanguage = document.getElementsByTagName('html')[0].getAttribute('lang');
        this.navigateToContentsValues = {
            'menu': {'pl': 'Przejdź do menu', 'en': 'Move to menu'},
            'article': {'pl': 'Przejdź do treści', 'en': 'Move to content'}
        };
        this.accessibilityValues =[
            {'className': 'accessible-font-1', 'title': {'pl': 'Domyślny rozmiar czcionki', 'en': 'Default font size'}, 'content': DefaultFontSizeImage},
            {'className': 'accessible-font-2', 'title': {'pl': 'Średni rozmiar czcionki', 'en': 'Medium font size'}, 'content': MediumFontSizeImage},
            {'className': 'accessible-font-3', 'title': {'pl': 'Duży rozmiar czcionki', 'en': 'Large font size'}, 'content': LargeFontSizeImage},
            {'className': 'accessible-spaces-1', 'title': {'pl': 'Domyślne odstępy tekstu', 'en': 'Default text spacing'}, 'content': DefaultTextSpacingImage},
            {'className': 'accessible-spaces-2', 'title': {'pl': 'Średnie odstępy tekstu', 'en': 'Medium text spacing'}, 'content': MediumTextSpacingImage},
            {'className': 'accessible-spaces-3', 'title': {'pl': 'Duże odstępy tekstu', 'en': 'Large text spacing'}, 'content': LargeTextSpacingImage},
            {'className': 'accessible-contrast-1', 'title': {'pl': 'Zmień kontrast na domyślny', 'en': 'Default kontrast'}, 'content': DefatultContrastImage},
            {'className': 'accessible-contrast-2', 'title': {'pl': 'Zmień kontrast na czarno-żółty', 'en': 'Black and yellow contrast'}, 'content': BlackAndYellowContrastImage},
            {'className': 'accessible-contrast-3', 'title': {'pl': 'Zmień kontrast na biało-czarny', 'en': 'White and black contrast'}, 'content': WhiteAndBlackContrastImage},
        ];
    }

    updateAccessibility(classUpdate) {
        const currentClasses = this.props.accessibilityClass.split(' ');
        const classPrefix = classUpdate.slice(0, -2);
        let updatedClasses = [];
        let exactExists = false;
        currentClasses.forEach((element) => {
            if (!element.startsWith(classPrefix)) {
                updatedClasses.push(element);
            } else if (element === classUpdate) {
                exactExists = true;
            }
        });
        if (!exactExists) {
            updatedClasses.push(classUpdate);
        }
        this.props.updateAccessibility(updatedClasses.join(' '));
    }

    render() {
        if (!this.isAccessible) {
            return null;
        }
        let accessibilityButtons = [];
        this.accessibilityValues.forEach((element) => {
            accessibilityButtons.push(
                <Button
                    key={element.className}
                    className='w-25 m-2 accessibility-icon'
                    variant='outline-secondary'
                    onClick={e => this.updateAccessibility(element.className)}
                    title={element.title[this.siteLanguage]}
                ><Image src={element.content} alt={element.title[this.siteLanguage]}/></Button>
            );
        });

        return (
            <Accordion>
                <Accordion.Item className='border-0' eventKey='0'>
                    <Accordion.Button className='accessibility-trigger'><Image className='w-100' src={AccessibilityImage} alt='accessibility'/></Accordion.Button>
                    <Accordion.Body className='w-100 p-0 dropdown-smaller-text'>
                        <Nav className='my-3 justify-content-center'>
                            <Nav.Item>
                                <Nav.Link href='#menu-contents' className='text-secondary' title={this.navigateToContentsValues.menu[this.siteLanguage]}>
                                    {this.navigateToContentsValues.menu[this.siteLanguage]}
                                </Nav.Link>
                                <Nav.Link href='#article-contents' className='text-secondary' title={this.navigateToContentsValues.article[this.siteLanguage]}>
                                    {this.navigateToContentsValues.article[this.siteLanguage]}
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <div className='row justify-content-center'>
                            {accessibilityButtons}
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        );
    }
}

export default AccessibilityForm;
