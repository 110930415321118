import React from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import FormSelect from 'react-bootstrap/FormSelect';
import Button from 'react-bootstrap/Button';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ListGroup from 'react-bootstrap/ListGroup';
import ModalFormWrapper from './ModalFormWrapper.js';

class ArticleLinkForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.prepopulateValues(this.props.articleElement);
        this.handleChange = this.handleChange.bind(this);
        this.updateTitle = this.updateTitle.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    prepopulateValues(element) {
        if (element !== null) {
            return {
                before: element.content.before,
                title: element.content.title,
                url: element.content.url,
                after: element.content.after,
                position: element.content.position,
                size: element.content.size
            }
        } else {
            return {before: '', title: '', url: '', after: '', position: '', size: ''}
        }
    }

    handleChange(e) {
        const key = e.target.name;
        this.setState({[key]: e.target.value});
    }

    updateTitle(e) {
        this.setState({title: e.target.innerText});
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.handleSubmit({
            kind: 'link',
            before: this.state.before,
            title: this.state.title,
            url: this.state.url,
            after: this.state.after,
            position: this.state.position,
            size: this.state.size
        });
    }

    handleCancel(e) {
        this.props.handleCancel();
    }

    render() {
        return (
            <ModalFormWrapper
                handleCancel={this.handleCancel}
                handleSubmit={this.handleSubmit}
                title='Edit article link element'
                content={
                    <>
                        <InputGroup className='mb-3'>
                            <InputGroup.Text id='link-before'>Text before the link</InputGroup.Text>
                            <FormControl
                                type='text'
                                aria-describedby='link-before'
                                name='before'
                                value={this.state.before}
                                onChange={this.handleChange}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <InputGroup.Text id='link-title'>Text of the link</InputGroup.Text>
                            <FormControl
                                type='text'
                                aria-describedby='link-title'
                                name='title'
                                value={this.state.title}
                                onChange={this.handleChange}
                            />
                            <DropdownButton
                                variant='outline-secondary'
                                title='Help'
                                id='input-group-dropdown-1'
                            >
                                <ListGroup>
                                    <ListGroup.Item><b>SPECIAL TITLES THAT RENDER EXTRA STUFF</b></ListGroup.Item>
                                    <ListGroup.Item><b>For social media icon write:</b></ListGroup.Item>
                                    <ListGroup.Item onClick={this.updateTitle}>facebooklogo</ListGroup.Item>
                                    <ListGroup.Item onClick={this.updateTitle}>facebooklogobig</ListGroup.Item>
                                    <ListGroup.Item onClick={this.updateTitle}>instagramlogo</ListGroup.Item>
                                    <ListGroup.Item onClick={this.updateTitle}>instagramlogobig</ListGroup.Item>
                                    <ListGroup.Item onClick={this.updateTitle}>youtubelogo</ListGroup.Item>
                                    <ListGroup.Item onClick={this.updateTitle}>youtubelogobig</ListGroup.Item>
                                    <ListGroup.Item><b>For youtube video thumbnail write:</b></ListGroup.Item>
                                    <ListGroup.Item onClick={this.updateTitle}>youtubefilm</ListGroup.Item>
                                    <ListGroup.Item><b>For iframe block write:</b></ListGroup.Item>
                                    <ListGroup.Item onClick={this.updateTitle}>iframeblock</ListGroup.Item>
                                </ListGroup>
                            </DropdownButton>
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <InputGroup.Text id='link-url'>Url (start with http:&#47;&#47; or https:&#47;&#47;)</InputGroup.Text>
                            <FormControl
                                type='text'
                                aria-describedby='link-url'
                                name='url'
                                value={this.state.url}
                                onChange={this.handleChange}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <InputGroup.Text id='link-after'>Text after the link</InputGroup.Text>
                            <FormControl
                                type='text'
                                aria-describedby='link-after'
                                name='after'
                                value={this.state.after}
                                onChange={this.handleChange}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <InputGroup.Text id='link-position'>Position</InputGroup.Text>
                            <FormSelect
                                aria-label='link-position'
                                aria-describedby='link-position'
                                name='position'
                                value={this.state.position}
                                onChange={this.handleChange}
                            >
                                <option value='left'>Aligned to the left</option>
                                <option value='center'>Centered</option>
                            </FormSelect>
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <InputGroup.Text id='link-text-size'>Link text size</InputGroup.Text>
                            <FormSelect
                                aria-label='link-position'
                                aria-describedby='link-text-size'
                                name='size'
                                value={this.state.size}
                                onChange={this.handleChange}
                            >
                                <option value='regular'>Regular</option>
                                <option value='small'>Small</option>
                                <option value='smallest'>Smallest</option>
                            </FormSelect>
                        </InputGroup>
                    </>
                }
                buttons={
                    <>
                        <Button variant='danger' onClick={this.handleCancel}>Cancel</Button>
                        <Button type='submit' variant='success'>Submit</Button>
                    </>
                }
            />
        );
    }
}

export default ArticleLinkForm;
